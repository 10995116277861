<template>
    <div class="register">
      <form @submit="onSubmit" novalidate>
        <vue-form-json-schema
          v-model="model"
          :schema="schema"
          :ui-schema="uiSchema"
          :options="options"
          v-on:state-change="onChangeState"
          v-on:validated="onValidated"
          ref="registerForm"
        />
        <div class="form-group">
          <v-btn type="submit"
                 color="primary"
                 elevation="2"
                 class="ma-2"
                 style="color: white;"
                 :loading="loading">
            {{$t('features.register')}}
          </v-btn>
        </div>
        <transition name="fade" mode="out-in">
          <div
            v-if="submitted && success"
            class="alert alert-success"
            key="success"
          >{{$t('forms.submitSuccess')}}</div>
        </transition>
      </form>
      <router-link to="Login">{{$t('features.loginHere')}}</router-link>

    </div>
</template>

<script>
import VueFormJsonSchema from 'vue-form-json-schema/dist/vue-form-json-schema.esm.js'
import form from '@/assets/formSchemas/register'
import { mapActions } from 'vuex'
import AuthMsgProcessor from '@/components/notifications/auth_msgs'
export default {
  name: 'RegisterForm',
  data () {
    return {
      model: {
        email: null,
        password: null
      },
      state: {},
      submitted: false,
      success: false,
      loading: false,
      valid: false,
      schema: form.schema,
      uiSchema: form.uiSchema,
      options: {
        castToSchemaType: true
      }
    }
  },
  methods: {
    ...mapActions({
      doRegister: 'login/doRegister'
    }),
    onChangeState (value) {
      this.state = value
    },
    onValidated (value) {
      this.valid = value
    },
    async onSubmit (e) {
      e.preventDefault()
      this.options = {
        ...this.options
        // showValidationErrors: true
      }
      if (this.valid) {
        this.loading = true
        this.submitted = true

        this.doRegister(this.model).then(res => {
          if (res) {
            this.loading = false
            this.$router.push('/alarms')
          } else {
            this.loading = false
            this.state.vfjsErrors.push(res)
          }
        })
      } else {
        console.log(this.state, 'this is not valid')
        this.state.email.vfjsFieldErrors.length > 0 && AuthMsgProcessor('err', 'emailIssue')
        this.state.password.vfjsFieldErrors.length > 0 && AuthMsgProcessor('err', 'psswdIssue')
      }
    }
  },
  mounted () {
    if (this.$route.params) this.model.email = this.$route.params.email
  },
  components: { VueFormJsonSchema }
}
</script>
