<template>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Login',
  computed: {
    ...mapGetters({
      isLoggedIn: 'login/isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      doLogout: 'login/doLogout'
    })
  },
  mounted () {
    if (this.isLoggedIn) this.doLogout()
  },
  components: { LoginForm }
}
</script>
