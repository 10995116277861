export default [
  {
    component: 'div',
    fieldOptions: {
      class: ['form-group']
    },
    children: [
      {
        component: 'label',
        fieldOptions: {
          attrs: {
            for: 'email'
          },
          class: ['font-weight-bold'],
          domProps: {
            innerHTML: 'Email address'
          }
        }
      },
      {
        component: 'b-form-input',
        model: 'email',
        errorOptions: {
          class: ['is-invalid']
        },
        fieldOptions: {
          class: ['form-control'],
          on: ['input'],
          props: {
            type: 'email'
          }
        }
      }
    ]
  },
  // Validation messages for email
  {
    component: 'transition',
    fieldOptions: {
      props: {
        name: 'fade'
      }
    },
    children: [
      {
        component: 'div',
        model: 'email',
        errorHandler: true,
        displayOptions: {
          model: 'email',
          schema: {
            not: {
              type: 'string'
            }
          }
        },
        fieldOptions: {
          class: ['alert alert-danger']
        },
        children: [
          {
            component: 'div',
            fieldOptions: {
              domProps: {
                innerHTML: 'This field is required'
              }
            }
          }
        ]
      }
    ]
  },
  {
    component: 'div',
    fieldOptions: {
      class: ['form-group']
    },
    children: [
      {
        component: 'label',
        fieldOptions: {
          attrs: {
            for: 'password'
          },
          class: ['font-weight-bold'],
          domProps: {
            innerHTML: 'Password'
          }
        }
      },
      {
        component: 'b-form-input',
        model: 'password',
        fieldOptions: {
          class: ['form-control'],
          on: ['input'],
          props: {
            type: 'password'
          }
        }
      }
    ]
  },
  // Validation messages for password
  {
    component: 'transition',
    fieldOptions: {
      props: {
        name: 'fade'
      }
    },
    children: [
      {
        component: 'div',
        model: 'password',
        errorHandler: true,
        displayOptions: {
          model: 'password',
          schema: {
            not: {
              type: 'string'
            }
          }
        },
        fieldOptions: {
          class: ['alert alert-danger']
        },
        children: [
          {
            component: 'div',
            fieldOptions: {
              domProps: {
                innerHTML: 'This field is required'
              }
            }
          }
        ]
      }
    ]
  }
]
