<template>
  <div>
    <RegisterForm />
  </div>
</template>

<script>
import RegisterForm from '@/components/forms/RegisterForm'

export default {
  components: { RegisterForm }
}
</script>
