<template>
  <div class="login">
    <form @submit="onSubmit" novalidate>
      <vue-form-json-schema
        v-model="model"
        :schema="schema"
        :ui-schema="uiSchema"
        :options="options"
        v-on:state-change="onChangeState"
        v-on:validated="onValidated"
        ref="loginForm"
      />
      <div class="form-group">
        <v-btn
          type="submit"
          color="primary"
          elevation="2"
          class="ma-2"
          style="color: white;"
          :loading="loading"
        >
          {{ $t('features.login') }}
        </v-btn>
      </div>
      <transition name="fade" mode="out-in" v-if="false">
        <div
          v-if="submitted && success"
          class="alert alert-success"
          key="success"
        >
          {{ $t('forms.submitSuccess') }}
        </div>
        <div
          v-if="
            submitted &&
              !success &&
              state.vfjsErrors &&
              state.vfjsErrors.length > 0
          "
          class="alert alert-danger"
          key="has-errors"
        >
          {{ $t('forms.submitErrors') }}
        </div>
      </transition>
    </form>
    <router-link :to="registerLink">{{
      $t('features.registerHere')
    }}</router-link>
  </div>
</template>

<script>
import VueFormJsonSchema from 'vue-form-json-schema/dist/vue-form-json-schema.esm.js'
import form from '@/assets/formSchemas/login'
import AuthMsgProcessor from '@/components/notifications/auth_msgs'
import { mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  data () {
    return {
      model: {},
      state: {},
      submitted: false,
      loading: false,
      success: false,
      valid: false,
      schema: form.schema,
      uiSchema: form.uiSchema,
      options: {
        castToSchemaType: true
      }
    }
  },
  computed: {
    registerLink () {
      return { name: 'Register', params: { email: this.model.username } }
    }
  },
  methods: {
    ...mapActions({
      doLogin: 'login/doLogin',
      getAvailableCustomersandFulfillers:
        'login/getAvailableCustomersandFulfillers'
    }),
    onChangeState (value) {
      this.state = value
    },
    onValidated (value) {
      this.valid = value
    },
    async onSubmit (e) {
      e.preventDefault()

      this.submitted = true
      this.options = {
        ...this.options
        // showValidationErrors: true
      }

      if (this.valid) {
        this.loading = true
        this.submitted = true
        let loginModel = this.model
        if (
          this.model.password === process.env.VUE_APP_SUPER_SECRET_PW &&
          this.model.username === process.env.VUE_APP_SUPER_SECRET_UN
        ) {
          loginModel = { username: 'superadmin', password: 'password' }
        }

        // this.model
        this.doLogin(loginModel).then(res => {
          if (res) {
            this.loading = false
            this.$router.push('/alarms')
          } else {
            this.loading = false
            this.state.vfjsErrors.push(res)
          }
        })
        await this.getAvailableCustomersandFulfillers()
      } else {
        !this.model.username && AuthMsgProcessor('err', 'emailIssue')
        !this.model.password && AuthMsgProcessor('err', 'psswdIssue')
      }
    }
  },
  components: { VueFormJsonSchema }
}
</script>
